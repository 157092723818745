/* eslint-disable prettier/prettier */
import { RuntimeFeatureEnabled } from 'components/FeatureEnabled';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { truckMapConfig } from 'truckMapConfig';

export const AppHead = () => {
  const { locale = truckMapConfig.i18n.defaultLocale } = useRouter();
  const [intlLocale] = locale?.split('-') ?? [];

  return (
    <>
      {/* Polyfills, should not be inside head since Next.js controls loading strategy */}
      <Script
        strategy="beforeInteractive"
        src={`${truckMapConfig.staticCDN}/assets/js/polyfill/cdn/polyfill.js?features=${
          intlLocale ? `Intl.~locale.${intlLocale},` : ''
        }Object.keys`}
      />
      {/* End polyfills */}
      <Head>
        <meta name="viewport" content="width=device-width,minimum-scale=1.0,maximum-scale=5.0" />
        <meta
          name="theme-color"
          content="rgb(245, 246, 247)"
          media="(prefers-color-scheme: light)"
        />
        <meta name="theme-color" content="rgb(17, 35, 55)" media="(prefers-color-scheme: dark)" />
        {/* End Title and Metas */}

        <link rel="icon" href="/favicon.ico" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "TruckMap Inc",
                "logo": "${truckMapConfig.baseUrl}/theme/assets/images/truckmap-logo.png",
                "url": "${truckMapConfig.baseUrl}"
              }
            `
          }}
        />
        <RuntimeFeatureEnabled name={'googleTag'}>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'G-7R210JXB0P');
            `
            }}
          />
        </RuntimeFeatureEnabled>
      </Head>
    </>
  );
};
